<template>
  <div>
    <vs-card>
      <vs-row class="gap">
        <vs-col vs-w="10"><h4>MR Change File Name</h4></vs-col>
        <vs-col vs-w="2">
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata"
          ></vs-button
        ></vs-col>
      </vs-row>

      <vs-row class="gap">
        <vs-col vs-w="3">
          <v-select
            style="width: 100%"
            placeholder="TYPE"
            v-model="type"
            :options="types"
          ></v-select>
        </vs-col>

        <vs-col vs-w="3">
          <vs-input class="inputx" placeholder="MR Number" v-model="mr_number"
        /></vs-col>
        <vs-col vs-w="3">
          <datepicker
            placeholder="Select Date"
            :format="customFormatter"
            v-model="dates"
          ></datepicker>
        </vs-col>
        <vs-col vs-w="3">
          <vs-button
            v-show="hideSendButton"
            color="primary"
            @click="changeFileName()"
            ><vs-icon class="icon_style" icon="check_circle"></vs-icon
            >&nbsp;&nbsp;Change File Name</vs-button
          >
          <vs-chip
            v-show="hiddenSuccessMessage"
            color="success"
            style="height: 36px"
          >
            <vs-avatar icon="check_circle" />
            <span style="font-size: 1rem">File Name Changed</span>
          </vs-chip>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>
  
  <script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import datetime from "vuejs-datetimepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
    "v-select": vSelect,
    DateRangePicker,
    datetime,
  },
  data() {
    return {
      hideSendButton: true,
      hiddenSuccessMessage: false,
      type: "",
      types: ["Terminal", "UPI", "PAY U"],
      mr_number: "",
      dates: "",
    };
  },
  watch: {
    dates(val) {
      console.log("dates", val);
    },
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    refreshdata() {
      this.type = null;
      this.mr_number = "";
      this.dates = "";
      this.hiddenSuccessMessage = false;
      this.hideSendButton = true;
    },
    changeFileName() {
      this.$vs.loading();
      let selected_date = moment(this.dates);
      let dateselected = moment(selected_date._i, "DD-MM-YYYY HH:mm:ss").format(
        "DD-MMM-YYYY"
      );
      // let url = `${constants.SERVER_API}preSignedUrlForFileUpload`;

      let obj = {
        document_type: this.type,
        mr_number: this.mr_number,
        mr_date: dateselected,
      };
      console.log("obj", obj);
      if (this.type != null && this.mr_number != "" && this.dates != "") {
      let url = `${constants.MILES_GOOGLE_INVITE}mrUploadRename?mr_number=${this.mr_number}&selected_date=${dateselected}&type=${this.type}`;
        axios
          .get(url, {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
            })
          .then((response) => {
            console.log("response", response.data.status);
            if(response.data.status == 200) {
              this.hiddenSuccessMessage = true;
              this.hideSendButton = false;
              this.$vs.notify({
                title: "Renamed Successfully",
                color: "success",
              });
            } else if(response.data.status == 400) {
              this.hiddenSuccessMessage = false;
              this.hideSendButton = true;
              this.$vs.notify({
              title: "This file is not found",
              color: "danger",
            });
            }else if(response.data.status == 500) {
              this.hiddenSuccessMessage = false;
              this.hideSendButton = true;
              this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
            }else if(response.data.status == 201) {
              this.hiddenSuccessMessage = false;
              this.hideSendButton = true;
              this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
            }
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close();
          });
      } else {
        this.$vs.notify({
          title: `Please fill all the fields`,
          text: "",
          color: "danger",
        });
      }  
    },
  },
};
</script>
  
  <style>
.icon_style {
  font-size: 17px;
  top: 3px;
  position: relative;
}
.con-vs-avatar {
  background: rgb(255 255 255);
}

.vs-avatar--text.notranslate.material-icons.check_circle.material-icons {
  color: rgb(60 197 70);
  font-size: 20px;
}
</style>
  